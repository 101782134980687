// IMPORTANT: Update this import to reflect the location of your Stimulus application
// See https://github.com/palkan/view_component-contrib#using-with-stimulusjs
import { application } from '../controllers'

// https://vitejs.dev/guide/features.html#glob-import
const controllers = import.meta.globEager('./**/controller.js')

for (let path in controllers) {
  let module = controllers[path]
  let name = path.replace(/\/controller\.js$/, '').replace(/^\.\//, '').replace('_', '-')
  application.register(name, module.default)
}
