import { useEmitter } from '../mixins/useEmitter'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['formContainer']

  connect () {
    useEmitter(this)
  }

  toggleForm () {
    this.parentId = this.data.get('parentId')

    this.emit('comments:toggle-reply', {
      formContainer: this.formContainerTarget,
      parentId: this.parentId
    })
  }
}
