import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect () {
    const { default: Modal } = await import('bootstrap/js/dist/modal')
    this.modalInstance = Modal.getOrCreateInstance(this.element)

    this.handleCloseModal = this.#handleCloseModal.bind(this)
    this.#init()
  }

  disconnect () {
    this.element.removeEventListener('hidden.bs.modal', this.handleCloseModal)
  }

  #init () {
    const hash = window.location.hash.split('#')[1]
    const { id } = this.element

    if (hash == id) {
      this.modalInstance.show()
      this.element.addEventListener('hidden.bs.modal', this.handleCloseModal)
    }
  }

  #handleCloseModal () {
    this.#clearHash()
  }

  #clearHash () {
    window.location.hash = ''
  }
}
