import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  // connect() {
  //   this.contentInput = document.getElementById('editorContent')
  //   this.formTarget.addEventListener('submit', this.formSubmit.bind(this))
  // }

  // formSubmit(event) {
  //   event.preventDefault()
  //   this.contentInput.value = window.editor.getHTML()
  //   Turbo.navigator.submitForm(this.formTarget)
  // }
}
