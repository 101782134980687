import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    this.url = this.data.get('url')
  }

  showForm (event) {
    event.preventDefault()
    if (this._isFormRendered()) { return }

    this._fetchForm()
  }

  // -----------------------------------------------------------------------------------------------

  _fetchForm () {
    Rails.ajax({
      url: this.url,
      type: 'get',
      success: this._renderForm.bind(this),
      error: this._renderError.bind(this)
    })
  }

  _renderForm (data, status, xhr) {
    const commentContainer = this.element.closest('.comment')
    const commentBody = commentContainer.querySelector('.comment__body')

    commentContainer.insertAdjacentHTML('beforeend', xhr.response)
    commentBody.classList.add('d-none')
  }

  _renderError () {
    this.element.remove()
  }

  _isFormRendered () {
    return !!this.element.closest('.comment').querySelector('#editCommentForm')
  }
}
