import { Controller } from '@hotwired/stimulus'
import { useRecaptcha } from '../mixins/useRecaptcha'

export default class extends Controller {
  static targets = ['input']
  static values = {
    action: String
  }

  async connect () {
    useRecaptcha(this)
  }

  async handleSubmit (event) {
    event.preventDefault()

    try {
      const token = await this.getToken(this.actionValue)
      this.setToken(this.inputTarget, token)
      this.element.submit()
    } catch (e) {
      console.error(e)
    }
  }
}
