import { load } from 'recaptcha-v3'

const recaptcha = {
  recaptchaInstance: null,

  async getToken (action) {
    if (!this.recaptchaInstance) {
      const siteKey = document.querySelector('meta[name=recaptcha-v3-site-key]').content
      this.recaptchaInstance = await load(siteKey)
    }

    const token = await this.recaptchaInstance.execute(action)

    return token
  },

  setToken (input, token) {
    input.value = token
  }
}

export const useRecaptcha = controller => {
  Object.assign(controller, recaptcha)
}
